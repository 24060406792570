import SaController from '../sa_controller';

export default class extends SaController {
  static targets = ['selections', 'options', 'selected', 'add', 'input'];
  static values = {
    limit: Number,
    values: Array,
    required: Boolean,
    ghost: Object,
    initialOpen: Boolean,
    submitting: Boolean
  }

  connect() {
    this.element.controller = this;
    this.element.addEventListener('popup:close', this.checkGhost);
    this.addTarget.addEventListener('click', 
      function(event) {
          event.preventDefault();
      }
    );
    this.valuesValueChanged();

    if(this.initialOpenValue) {
      this.open();
    }
  }

  optionsTargetConnected() {
    this.valuesValueChanged();
  }

  valuesValueChanged() {
    this.optionsController?.updateValues(this.valuesValue);
    this.updateValues(this.valuesValue);
    this.inputTarget.value = this.valuesValue.map((v) => v.id).join(',');

    if(this.valuesValue.length >= this.limitValue) {
      this.addTarget.classList.add('hidden');
      this.element.popupController?.close();
    } else {
      this.addTarget.classList.remove('hidden');
    }
    
    if(this.valuesValue.length == 0 && this.requiredValue) {
      this.ghost();
    }
 
    if(this.submittingValue && this.valuesValue.length > 0) {
      //let form = this.element.closest("form")
      //form.submit();
      this.element.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }
  }

  remove(event) {
    event.preventDefault();
    event.stopPropagation();

    const id = event.params.id;
    this.ghostValue = this.valuesValue.find((v) => v.id === id);
    this.valuesValue = this.valuesValue.filter((v) => v.id !== id);
    this.valuesValue = this.valuesValue.filter((v) => v !== id);
    this.open();

    setTimeout(() => {
      this.element.dispatchEvent(new CustomEvent('multibox:removed', {bubbles: true}))
    });
  }

  add(event) {
    const id = event.params.id;
    const name = event.params.name;
    const avatar = event.params.avatar;

    if(this.valuesValue.length >= this.limitValue) {
      this.valuesValue = [...this.valuesValue.slice(0, this.valuesValue.length-1), { id, name, avatar }];
    } else {
      this.valuesValue = [...this.valuesValue, { id, name, avatar }];
    }

    setTimeout(() => {
      this.element.dispatchEvent(new CustomEvent('multibox:selected', {bubbles: true}))
    });
  }

  open() {
    if(this.addTarget.classList.contains('closer')) {
      this.element.popupController?.close();
      this.addTarget.classList.remove('closer');
    } else {
    setTimeout(() => {
      this.element.popupController?.open();
      this.optionsController?.open();
      this.addTarget.classList.add('closer');
      });
    }
  }

  stringToElement(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body.firstChild;
  };

  get optionsController() {
    return this.optionsTarget?.controller;
  }

  checkGhost = (event) => {
    if(this.valuesValue.length == 0 && this.requiredValue) {
      this.valuesValue = [this.ghostValue];
    }
  } 

  ghost() {
    if(Object.keys(this.ghostValue).length != 0) {
      this.addTarget.insertAdjacentElement('beforebegin', this.stringToElement(
        `<div class="rounded-full px-0.5 pr-2 border border-gray-300 bg-gray-100 selection opacity-60 cursor-pointer" data-sa--multibox-id-param="${this.ghostValue.id}" data-sa--multibox-target="selected">
          ${this.renderAvatar(this.ghostValue.avatar)}
          ${this.ghostValue.name}
        </div>`));
    }
  }

  updateValues(values) {
    this.selectedTargets.forEach((option) => { option.remove(); });

    values.forEach((v) => {
      this.addTarget.insertAdjacentElement('beforebegin', this.stringToElement(
        `<div class="rounded-full px-0.5 pr-1 border border-gray-300 bg-gray-100 selection hover:ring-1 cursor-pointer" data-action="click->sa--multibox#remove" data-sa--multibox-id-param="${v.id}" data-sa--multibox-target="selected">
          ${this.renderAvatar(v.avatar)}
          ${v.name}
          <svg class="w-4 h-4 inline-block ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </div>`));
    });
  }

  renderAvatar(avatar) {
    if(avatar) {
       return `<img src="${avatar}" class="w-6 h-6 rounded-full inline-block" />`
     } else {
       return ""
    }
  }



}
