import SaController from './sa_controller';

/*************************************
 * Grab preview from an uploader while
 * the uploader is uploading the file
 *************************************/

export default class extends SaController {
  connect() {
    this.element.previewController = this;
    this.grabPreview();
  }

  disconnect() {
  }

  // Whoever is above that grabs this event will call setPreview
  grabPreview() {
    this.element.dispatchEvent(new CustomEvent('uploader:grab-preview', {detail: {assetId: this.id}, bubbles: true}));
  }

  setPreview(blob) {
    const url = URL.createObjectURL(blob);
    if(this.hasImageTarget) {
      this.imageTarget.src = url;
    } else {
      this.element.src = url;
    }
  }

  get id() {
    return this.element.dataset.assetId;
  }
}
