import SaController from '../sa_controller';
import {computePosition, offset, flip} from '@floating-ui/dom';

export default class extends SaController {
  static targets = ['clear', 'checkbox', 'drawer']
  static values = {
    cacheInitialState: Boolean
  }

  connect() {
    this.drawerCache = {};
    if(this.cacheInitialStateValue) {
      this.drawerTargets.forEach((drawer) => {
        this.drawerCache[drawer.dataset.id] = drawer.innerHTML;
      })
    }
  }

  disconnect() {
  }

  checkboxTargetConnected(target) {
    target.addEventListener('change', this.checkClose);
  }

  checkboxTargetDisconnected(target) {
    target.removeEventListener('change', this.checkClose);
  }

  checkClose = (event) => {
    const lockQuery = event.target.dataset.lockQuery 
    if(lockQuery) {
      const lock = this.element.querySelector(lockQuery);
      if(lock) {
        if(lock.dataset.lockMessage) {
          alert(lock.dataset.lockMessage);
        }
        event.target.checked = true;
      }
    }
  }

  toggleDrawer(event) {
    const id = event.params.id;
    const checkbox = this.element.querySelector(`#${id}`);
    checkbox.checked = !checkbox
  }

  showDrawer(event) {
    // Close all other drawers
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    })

    const id = event.params.id;
    const checkbox = this.element.querySelector(`#${id}`);
    checkbox.checked = true;

    this.drawerTargets.forEach((drawer) => {
      if(drawer.dataset.id == id && this.drawerCache[id]) {
        drawer.innerHTML = this.drawerCache[id];
      }
    });

    // Clear any targets we have set
    this.clearTargets.forEach((clear) => {
      clear.innerHTML = "";
    });

  }

  hideAllDrawer(event) {
    this.drawerTargets.forEach((drawer) => {
      const checkbox = this.element.querySelector(`#${drawer.dataset.id}`);
      checkbox.checked = false;
    })
  }
  
  hideDrawer(event) {
    const id = event.params.id;
    const checkbox = this.element.querySelector(`#${id}`);
    checkbox.checked = false;
  }
}
