import SaController from './sa_controller';

export default class extends SaController {
  static values = {
    baseSrc: String,
    smSrc: String,
    mdSrc: String,
    lgSrc: String,
    xlSrc: String
  }

  connect() {
    window.addEventListener('resize', this.resizeHandler);
    this.load();
  }

  disconnect() {
    window.addEventListener('resize', this.resizeHandler);
  }

  load = () => {
    const src = this.expectedSrc;
    // Don't reload if the src is the same
    if(this.currentSrcMatch(src)) { return }  
    this.element.src = src;
  }
  resizeHandler = this.debounce(this.load, 500);

  currentSrcMatch(src) {
    const currentSrc = this.element.src;
    if (!currentSrc) { return false }
    const url = new URL(currentSrc);
    const urlWithoutHost = url.pathname + url.search + url.hash;

    return urlWithoutHost === src;
  }

  get expectedSrc() {
    const width = window.innerWidth;
    return this.getSrc(this.getBreakpoint(width));
  }

  getBreakpoint(width) {
    if (width < 576) {
      return 'xs';
    } else if (width < 768) {
      return 'sm';
    } else if (width < 992) {
      return 'md';
    } else if (width < 1200) {
      return 'lg';
    } else {
      return 'xl';
    }
  }

  get srcMap() {
    // Kinda hacky 
    return {
      'xs': this.baseSrcValue,
      'sm': this.smSrcValue || this.baseSrcValue,
      'md': this.mdSrcValue || this.smSrcValue || this.baseSrcValue,
      'lg': this.lgSrcValue || this.mdSrcValue || this.smSrcValue || this.baseSrcValue,
      'xl': this.xlSrcValue || this.lgSrcValue || this.mdSrcValue || this.smSrcValue || this.baseSrcValue
    }
  }

  getSrc(breakpoint) {
    return this.srcMap[breakpoint];
  }

  // Debounce function
  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    }
  }
}
