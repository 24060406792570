import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  get csrfToken() {
    // @ts-ignore
    return document.querySelector('[name="csrf-token"]')?.content;
  }

  get headers() {
    return {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': this.csrfToken
    };
  }

  slottedInTheme(element) {
    // Some weirdness in controllers getting connected twice 
    // when contained in a theme. 
    let theme = element.closest("sa-theme");
    if(theme && !theme.classList.contains("block")) {
      return false;
    } else {
      return true
    }
  }

  emit(name, detail, target) {
    let event = new CustomEvent(name, {detail})
    if(target) {
      target.dispatchEvent(event);
    } else {
      window.dispatchEvent(event);
    }
  }

  emitEvent(name, detail) {
    this.emit(name, detail, this.element);
  }

  post(url, data) {
    return fetch(url, {
      method: 'POST', 
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  put(url, data) {
    return fetch(url, {
      method: 'PUT', 
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  get(url) {
    return fetch(url, {
      method: 'GET', 
      headers: this.headers,
    })
  }

  request(method, url, data) {
    switch(method) {
      case 'post':
        this.post(url, data);
        break;
      case 'put':
        this.put(url, data);
        break;
      default:
        // TODO
    }
  }
}
