import SaController from '../sa_controller';
import '../../elements/progress_ring';
import MediaInfo from 'mediainfo.js';
export default class extends SaController { 
  static targets = ['file', 'presigned'];
  static values = { createUrl: String };


  connect() {
    this.fileTarget.addEventListener('change', this.initiateUpload.bind(this));
    this.files = {};

    MediaInfo({ format: 'JSON', locateFile: () => 'https://assets.socialassurance.com/mediainfo/MediaInfoModule.wasm' }, (mediainfo) => {
      this.mediainfo = mediainfo;
    });
  }

  presignedTargetConnected = (element) => {
    console.log("PresignedUploadController connected");
    const uploadController = element.presignedUploadController;
    uploadController.startUpload(this.files[uploadController.assetValue.asset.name]);
  }
  
  upload= (event) => {
    this.fileTarget.click();
  }

  async fileStats(file) {
    const getSize = () => file.size;
    const readChunk = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
      })

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.mediainfo.analyzeData(getSize, readChunk).then((result) => {
          resolve(result);
        }, 1000);
      });
    });
  }

  initiateUpload = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      const stats = await this.fileStats(file);
      const name = file.name.replace("+", "");
      this.files[name] = file;
      this.post(this.createUrlValue,
        { content_type: file.type,
          size: file.size,
          secured: false,
          name: name,
          info: stats
        })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    })
    this.fileTarget.value = null;
  }
}