import SaController from '../sa_controller';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default class extends SaController {
  static targets = [ 'anchor' ]
  static values = { stops: String }

  connect() {
    console.log("Tour Controller Connected");
    if(this.okToRun) {
      this.runTour();
    }
  }

  disconnect() {
  }

  get stopsJSON() {
    return JSON.parse(this.stopsValue);
  }

  get okToRun() {
    // screen width greater than 768px
    return window.innerWidth >= 640;
  }

  checkForElements() {
    return this.stopsJSON.filter((stop) => {
      return stop
      // I want this to work at some point, but rn it won't work for tasks since some of the elements don't load until partway through the tour
      /*if (stop.hasOwnProperty("element")) {
        if (document.querySelector(stop.element)) {
          return stop;
        }
      } else {
        return {
          stop
        }
      }*/
    });
  }

  nextClick(nextClickElement, nextElement) {
    document.querySelector(nextClickElement).click();
    if (nextElement) {
      this.waitForElement(nextElement);
    } else {
      this.driverObj.moveNext();
    }
  }

  waitForElement(element) {
    const interval = setInterval(() => {
      if (document.querySelector(element)) {
        clearInterval(interval);
        this.driverObj.moveNext();
      }
    }, 100);
  }

  parseTourData() {
    const tourData = this.checkForElements();
    const tourStops = tourData.map((datum) => {
      if (datum.hasOwnProperty("element")) {
        return {
          element: datum.element,
          popover: {
            title: datum.popover.title,
            description: datum.popover.description,
            position: datum.popover.position,
            nextBtnText: datum.popover.nextBtnText || "Next",
            onNextClick: () => {
              if(datum.nextClickElement) {
                this.nextClick(datum.nextClickElement, datum.nextElement);
              } else {
                this.driverObj.moveNext();
              }
            }
          },
        }
      } else {
        return {
          popover: {
            title: datum.popover.title,
            description: datum.popover.description,
            showButtons: datum.popover.showButtons,
            nextBtnText: datum.popover.nextBtnText || "Next",
            onNextClick: () => {
              if(datum.nextClickElement) {
                this.nextClick(datum.nextClickElement);
              } else {
                this.driverObj.moveNext();
              }
            }
          }
        }
      } 
    });
    return tourStops;
  }

  runTour() {
    this.driverObj = driver({
      showProgress: false,
      steps: this.parseTourData()
    });
    this.driverObj.drive();
  }
  
}
