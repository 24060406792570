import Flatpickr from "stimulus-flatpickr";
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'

import "flatpickr/dist/themes/dark.css";

import { english } from "flatpickr/dist/l10n/default.js";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {  
  static targets = ['toggle']

  get calendarToggleOptions() {
    if (this.hasToggleTarget) {
      return {
        clickOpens: !this.hasToggleTarget,
        altInputClass: this.hasToggleTarget ? '-z-50 absolute max-w-6 w-6 p-1' : ''
      }
    } else {
      return {}
    }
  }

  get plugins() {
    let obj = {}
    if (this.element.dataset.range) {
      obj = Object.assign(obj, { plugins: [new rangePlugin( { input: "#" + this.element.dataset.secondaryTarget } )] })
    }

    return obj
  }

  get local_l10n() {
    // available options: <flatpickr src>:src/types/locale.ts
    if (this.element.dataset.localeOverrides) {
      return Object.assign(english, JSON.parse(this.element.dataset.localeOverrides))
    } else {
      return english
    }
  }

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = Object.assign(
      this.calendarToggleOptions,
      this.plugins,
      {
        locale: english,
        theme: 'dark',
        dateFormat: this.element.getAttribute('data-flatpickr-date-format') || 'Y/m/d',
        altInput: true,
        l10n: this.local_l10n,
        altFormat: this.element.getAttribute('data-flatpickr-alt-format') || 'd/m/Y',
        enableTime: this.element.hasAttribute('data-flatpickr-enable-time'),
        allowInput: true,
        required: this.element.hasAttribute('required'),
        onClose: () => this._open = false,
        onOpen: () => this._open = true,
      },
      this.options
    );

    if (this.hasToggleTarget) {
      this.toggleTarget.addEventListener('click', this.toggle)
    }

    super.connect();

    this._open = false

    // Removing Dashlane autofill
    this.element.nextSibling.dataset.formType = 'other'
  }

  disconnect() {
    if (this.hasToggleTarget) {
      this.toggleTarget.removeEventListener('click', this.toggle)
    }
  }

  get options() {
    return this.element.dataset.options ? JSON.parse(this.element.dataset.options) : {}
  }

  get value() {
    this.element.value;
  }

  // Could be common functions moved into base class
  get form() {
    return this.element.closest('form');
  }

  toggle = (evt) => {
    this._open ? this.fp.close() : this.fp.open()
    this._open = !this._open    
  }
}
