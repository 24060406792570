import ComposeController from '../compose_controller';
import { cancel, asString } from '../../svg';
import {debounce} from 'lodash';
// Import morphdom
import morphdom from 'morphdom';

export default class extends ComposeController {
  static targets = ['asset'];

  connect() {
    this.mediaController.element.addEventListener('assetSelected', this.assetSelected);
    this.mediaController.element.addEventListener('assetDeselected', this.assetDeselected);

    // watch for resize
    window.addEventListener('resize', this.repopulate);
    this.repopulate();
  }

  disconnect() {
    this.mediaController.element.removeEventListener('assetSelected', this.assetSelected);
    this.mediaController.element.removeEventListener('assetDeselected', this.assetDeselected);
  }


  assetSelected = (event) => {
    console.log('selected_media_controller assetSelected', event);
    this.repopulate();
  }

  assetDeselected = (event) => {
    console.log('selected_media_controller assetDeselected', event);
    this.repopulate();
  }

  get mediaController() {
    return this.element.closest('[data-controller=compose--media]').mediaController;
  }

  get gridColumnCount() {
    const gridComputedStyle = window.getComputedStyle(this.element);
    const count = gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length;
    return count;
  }

  buildAsset = (asset) => {
    return `<div class="relative aspect-square outline-1 hover:outline outline-red-600 rounded-lg cursor-pointer"data-compose--selected-media-target="asset" data-action="click->compose--media#selectAsset" data-compose--media-asset-id-param="${asset.assetId}">
       <div class="absolute -top-2 -right-2 w-5 h-5 z-10 hover:text-red-900 text-brand-default cursor-pointer" data-action="click->compose--media#removeMedia:stop" asset-id="${asset.assetId}" >
         ${asString(cancel)}
       </div>
       <div class="relative rounded-lg overflow-hidden border">
         <img class="aspect-square object-cover" src="${asset.poster || asset.preview || asset.src}" data-action="click->selected-media#assetClicked">
       </div>
    </div>`;
  }

  // Debounced
  repopulate = debounce(() => {
    const total = this.mediaController.assetTargets.length;

    let str = this.mediaController.assetTargets.slice(0,this.gridColumnCount).map(asset => {
      return this.buildAsset(asset);
    }).join('');

    if(this.mediaController.assetTargets.length === 0) {
      str = `<div class="col-span-full paragraph-100-light">Select from your gallery or upload media to be added to your post</div>`;
    }

    morphdom(this.element, `<div class="relative gap-3 grid grid-cols-[repeat(auto-fill,80px)]" data-controller="compose--selected-media">${str}</div>`)

    if(total > this.gridColumnCount) {
      const lastAsset = this.assetTargets[this.gridColumnCount-1];
      lastAsset.insertAdjacentHTML('afterbegin',
        `<div class="absolute z-10 inset-0 rounded-lg bg-gray-800 bg-opacity-50 flex items-center justify-center text-white text-xl">
          +${total - this.gridColumnCount}
        </div>`);
    }
  }, 100);
}
