import ComposeController from '../compose_controller';
import Sortable from '../sortable_controller'

export default class extends Sortable {
  static values = {
    offset: Number
  }

  connect() {
    this.element.sortableController = this;
    super.connect()
    if(!this.hasOffsetValue) {
      this.offsetValue = 0;
    }
  }

  get composeManagerController() {
    return this.element.closest('[data-controller=compose--manager]').composeManager;
  }

  onUpdate({item, newIndex}) {
    const controller = item.assetController;
    const mediaItemController = item.mediaItemController;
    const saAssetId = item.assetId;

    newIndex -= this.offsetValue;

    if (controller) {
      controller.takeAction('media', 'update_asset', {asset_id: controller.id, position: newIndex + 1});
    } else if (mediaItemController) {
      this.composeManagerController.takeAction('media', 'move_asset', {asset_id: mediaItemController.id, position: newIndex + 1});
    } else {
      this.composeManagerController.takeAction('media', 'update_asset', {asset_id: saAssetId, position: newIndex + 1});
    }
  }

  get options() {
    return {
      animation: 150,
      handle: undefined,
      filter: ".add-media",
      preventOnFilter: false,
      onMove: function(evt) {
        return evt.related.className.indexOf('add-media') === -1;
      },
      onUpdate: this.onUpdate
    }
  }
}
