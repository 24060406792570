import SectionController from '../compose/section_controller';
import Uppy from '@uppy/core';
import DragDrop from '@uppy/drag-drop';
import Dashboard from '@uppy/dashboard';
import MediaInfo from 'mediainfo.js';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import AwsS3 from '@uppy/aws-s3'
//import GoogleDrive from '@uppy/google-drive'

export default class extends SectionController {
  static targets = ['indicator', 'uppy', 'progress'];
  static values = {
    accept: String,
    options: Object,
    presigned: String,
    success: String,
    failure: String
  }

  connect() {
    window.addEventListener('loadUppy', this.loadUppy);


    MediaInfo({ format: 'JSON', locateFile: () => 'https://assets.socialassurance.com/mediainfo/MediaInfoModule.wasm' }, (mediainfo) => {
      this.mediainfo = mediainfo;
    });
  }

  indicatorTargetConnected = () => {
    this.indicatorTarget.addEventListener('click', this.loadUppy);
  }

  uppyTargetConnected = () => {
    if(!this.uppy) {
      this.uppy = new Uppy({
        //onBeforeUpload: this.beforeUpload,
        restrictions: {
          allowedFileTypes: this.acceptValue.split(',')
        }
      }).use(Dashboard, { 
        proudlyDisplayPoweredByUppy: false,
        inline: true,
        target: this.uppyTarget,
        height: '100%',
        singleFileFullScreen: false,
        showRemoveButtonAfterComplete: true,
        hideUploadButton: true,
        doneButtonHandler: null
      });
      //this.uppy.use(GoogleDrive, { companionUrl: 'http://localhost:3020' })
      this.uppy.use(AwsS3, { getUploadParameters: this.getUploadParameters })
      //this.uppy.use(AwsS3, { endpoint: 'http://localhost:3020' })
      this.uppy.on('complete', this.uppyComplete)

      this.uppy.on('upload-success', (file, response) => {
        console.log(file.name, response.uploadURL);

        // Emit custom event for the parent controller to listen to
        const event = new CustomEvent('uppycomplete', { detail: { files: [file.meta.asset] } })
        // TODO - window.dispatchEvent(event) isn't ideal
        window.dispatchEvent(event)

      });

      this.uppy.on('file-removed', (file, reason) => {
        console.log("File removed", file, reason);
        if (!file.keep) {
          this.composeManager.onUppyRemove(file);
        } else {
          file.keep = false;
        }
      });
      this.uppy.on('cancel-all', (evt) => {
        // Mark files for deletion
        this.uppy.getFiles().forEach((file) => {
          if(file.progress.uploadComplete) {
            file.keep = true;
          }
        });
      });
      this.uppy.on('file-added', (file) => {
        console.log("File added", file);
        this.uppy.upload(); 
      });
      this.uppy.on('progress', (progress) => {
        // progress: integer (total progress percentage)
        console.log(progress);
        this.indicatorTarget.classList.remove('hidden');
        this.progressTarget.textContent = `${progress}%`
        if(progress === 100 || progress === 0) {
          this.progressTarget.textContent = ''
          this.indicatorTarget.classList.add('hidden');
          //this.composeManager.hideUploads();
        }
      });
    } 
  }

  loadUppy = (event) => {
    if(!event.detail || !event.detail.preventBrowse) {
      setTimeout(() => {
        document.querySelector(".uppy-Dashboard-browse").click()
      }, 10);
    }
  }

  uppyComplete = (result) => {
    console.log("Upload complete", result);
    if (result.failed.length > 0) {
      this.put(this.failureValue, { files: result.failed.map((file) => file.meta.asset) })
    }   
  }

  async fileStats(file) {
    const getSize = () => file.size;
    const readChunk = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(file.data.slice(offset, offset + chunkSize))
      })

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.mediainfo.analyzeData(getSize, readChunk).then((result) => {
          resolve(result);
        }, 1000);
      });
    });
  }


  getUploadParameters = async (file) => {
    const stats = await this.fileStats(file);

    return  this.post(this.presignedValue, { name: file.name, content_type: file.type, size: file.size, info: stats, secured: this.secured })
                .then((response) => {
                  return response.json()
                }).then((data) => {

                  this.uppy.setFileMeta(file.id, Object.assign(file.meta, { asset: data.result.asset }))

                  return {
                    method: 'POST',
                    url: data.result.url,
                    fields: data.result.fields,
                    headers: {},
                  }
                })
  }
}
