import SaController from '../sa_controller';
import { createPicker } from 'picmo';
import tippy from "tippy.js";
import { Editor, Extension } from '@tiptap/core'
import Mention from '@tiptap/extension-mention';
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";
import { post, patch } from '@rails/request.js'
import HardBreak from "@tiptap/extension-hard-break";

export default class extends SaController {
    static targets = ['note', 'emojiPicker', 'emojiPopup', 'editor', 'mentionStarter', 'mentionTemplate', 'content'];
    static values = {
        taskCommentPath: String,
        text: String,
        maxChars: Number,
        canMention: Boolean,
        updating: Boolean
    }

    connect() {
        super.connect();

        this.editor = new Editor({
            element: this.editorTarget,
            editorProps: {
                transformPastedHTML: (html) => {
                    return html.replace(/<p><\/p>/g, "");
                }
            },
            extensions: [
                Document,
                Paragraph,
                Text,
                Placeholder.configure({
                    placeholder: "Add a comment"
                }),
                HardBreak.extend({
                    addKeyboardShortcuts () {
                        return {
                            Enter: () => this.editor.commands.setHardBreak()
                        }
                    }
                }),
                Mention.configure({
                    HTMLAttributes: {
                        class: 'px-1 rounded-sm bg-base-300 font-bold',
                    },
                    renderLabel({ options, node }) {
                        return `${"@"}${node.attrs.label ?? node.attrs.id}`
                    },
                    renderText({ options, node }) {
                        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
                    },
                    suggestion: this.suggestion,
                    deleteTriggerWithBackspace: true
                })
            ],
            content: JSON.parse(this.contentTarget.innerHTML),
        })
        this.textValue = this.editor.getText({ blockSeparator: "\n" })
        this.setupEmojiPicker();
        this.setupMentionStarter();
    }

    setupMentionStarter() {
        if (this.hasMentionStarterTarget) {
            this.mentionStarterTarget.addEventListener('click', () => {
                this.editor.commands.insertContent("@");
                this.editor.chain().focus();
            })
        }
    }

    setupEmojiPicker() {
        if (this.hasEmojiPickerTarget) {
            this.emojiPickerElement = document.createElement('div');
            this.emojiPicker = createPicker({rootElement: this.emojiPickerElement});
            this.emojiPicker.addEventListener('emoji:select', event => {
                this.editor.commands.insertContent(event.emoji);
                // TODO: Why does the emoji picker not close after selecting an emoji? Why does it not update recent emojis?
            });
            this.emojiPopup = tippy(this.emojiPickerTarget, {
                appendTo: () => document.body,
                content: this.emojiPickerElement,
                interactive: true,
                offset: [10, 10],
                placement: 'bottom-start',
                trigger: 'click'
            });
        }
    }

    get suggestion() {
        const controller = this;
        let currentQuery = "";

        return {
            items: ({ query }) => {
                currentQuery = query;
            },
            render: () => {
                let element;
                let popup;

                return {
                    onStart: props => {
                        // if(!controller.canMentionValue) return;

                        element = controller.createMentionArea();
                        if (!props.clientRect) {
                            return
                        }

                        setTimeout(() => {
                            element.mentionsController.setCommand(props.command);
                            element.mentionsController?.keyDown({query: currentQuery});
                        });

                        popup = tippy('body', {
                            getReferenceClientRect: props.clientRect,
                            appendTo: () => document.body,
                            content: element,
                            showOnCreate: true,
                            interactive: true,
                            trigger: 'manual',
                            placement: 'bottom-start'
                        })
                    },

                    onUpdate(props) {
                        if (!props.clientRect) {
                            return
                        }

                        setTimeout(() => {
                            element.mentionsController.setCommand(props.command);
                        });

                        popup[0].setProps({
                            getReferenceClientRect: props.clientRect,
                        })

                    },

                    onKeyDown(props) {

                        if (props.event.key === 'Escape') {
                            popup[0].hide()

                            return true
                        }

                        setTimeout(() => {
                            element.mentionsController?.keyDown({query: currentQuery});
                        })

                        //return component.ref?.onKeyDown(props)
                    },

                    onExit() {
                        popup[0].destroy()
                    },
                }
            }
        };
    }

    createMentionArea() {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.mentionTemplateTarget.innerHTML, "text/html");
        return doc.body.firstElementChild;
    }

    submit() {
        const message = JSON.stringify(this.editor.getJSON());
        const note = this.editor.getText({ blockSeparator: "\n" });
        if (this.editor.getText().trim() === "") {
            return;
        }
        else if (this.updatingValue) {
            patch(this.taskCommentPathValue, {
                responseKind: "turbo-stream",
                body: JSON.stringify({note: note, note_json: message})
            })
        } else {
            post(this.taskCommentPathValue, {
                responseKind: "turbo-stream",
                body: JSON.stringify({note: note, note_json: message})
            })
        }
        this.editor.commands.clearContent();
    }
}
