import { Controller } from "@hotwired/stimulus"
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['option', 'input', 'frame', 'valueInput']
  static values = {
    values: Array,
    searchUrl: String,
    fetchOnLoad: Boolean
  }

  connect() {
    console.log('Search box controller connected');
    // Generic controller naming so multibox can generically
    // connect to any search box type we might create
    this.element.controller = this;
    
    if(this.fetchOnLoadValue) {
      this.fetchData();
    }

    this.frameTarget.addEventListener('turbo:frame-load', this.filterValues);
    this.debouncedFetchData = debounce(this.fetchData, 300);
    this.element.addEventListener('keydown', this.keyHandler);
  }

  optionTargetConnected(target) {
    target.addEventListener('click', this.optionClick);
  }

  optionTargetDisconnected(target) {
    try {
      target.removeEventListeners('click', this.optionClick);
    } catch {
      console.log('Error removing event listener');
    }
  }

  optionClick = (event) => {
    const elm = event.currentTarget;
    if(this.hasValueInputTarget) {
      this.valueInputTarget.value = elm.dataset.id;
    }
    this.element.dispatchEvent(
      new CustomEvent('search-box:selected', { 
        detail: { 
          id: elm.dataset.id,
          name: elm.dataset.name }, 
        bubbles: true
      }));
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.keyHandler);
  }

  search(event) {
    this.debouncedFetchData();
  }

  open() {
    this.inputTarget.value = '';
    this.debouncedFetchData();
    setTimeout(() => {
      this.inputTarget.focus();
    })
  }

  updateValues(values) {
    this.valuesValue = values;
    this.filterValues();
    this.inputTarget.focus();
  }

  filterValues = () => {
    this.optionTargets.forEach((option) => {
      // If the value is not in the values array, show it
      if (!this.valuesValue.find((v) => v.id == option.dataset.id)) {
        option.classList.remove('hidden');
        option.focusable = false;
      } else {
        option.classList.add('hidden');
        option.focusable = true;
      }
    });
  }

  setContext(multibox) {
    this.multibox = multibox;
  }
  
  fetchData = () => {
    if(this.inputTarget.value != this.lastSearchInput) {
      // Support for searchUrlValue being a path with query params
      const url = new URL(window.location.origin + this.searchUrlValue);
      url.searchParams.append('search', this.inputTarget.value);
      this.frameTarget.src = url;
      this.lastSearchInput = this.inputTarget.value;
    }
  }

  get inputActive() {
    return document.activeElement === this.inputTarget;
  }

  get activeIndex() {
    return Array.from(this.availableOptions).indexOf(document.activeElement);
  }

  get availableOptions() {
    return this.optionTargets.filter(x => x.classList.contains('hidden') === false)
  }

  get optionCount() {
    return this.availableOptions.length;
  }

  get nextOption() {
    return this.availableOptions[this.activeIndex + 1];
  }

  get previousOption() {
    return this.availableOptions[this.activeIndex - 1];
  }

  keyHandler = (evt) => {
    // Navigate listTarget with arrow keys
    if (evt.key === 'ArrowDown') {
      evt.preventDefault();
      this.nextOption.focus();
    }
    if (evt.key === 'ArrowUp') {
      evt.preventDefault();
      try {
        this.previousOption.focus();
      } catch {
        this.inputTarget.focus();
      }
    }
    if (evt.key === 'Enter') {
      evt.preventDefault();
      if (this.inputActive) {
        this.nextOption.focus();
      } else {
        document.activeElement.click();
      }
    }
    if (evt.key === 'Escape') {
      evt.preventDefault();
      this.inputTarget.focus();
    }
    if (evt.key === 'Backspace') {
      // TODO remove last value from multibox?
    }
  }

}
