import SaController from './sa_controller';
import { post } from '@rails/request.js'
export default class extends SaController {
  static outlets = ['selectable-table']
  static targets = ['singleBar','multiBar']

  connect() {
    this.handleSelected({detail: []})
  }

  selectableTableOutletConnected(outlet, element) {
    element.addEventListener('selectable-table:selected', this.handleSelected)
  }

  selectableTableOutletDisconnected(outlet, element) {
    this.handleSelected({detail: []});
    element.removeEventListener('selectable-table:selected', this.handleSelected)
  }

  get tableSelectedBrands() {
    const nodeList = this.selectableTableOutlets.map((outlet) => Array.from(outlet.element.querySelectorAll('.selected [data-brand-id]'))).flat();
    return new Set(Array.from(nodeList).map((node) => node.dataset.brandId));
  }

  get tableSelectedIds() {
    return this.selectableTableOutlets.map((outlet) => outlet.selectedValue).flat();
  }

  handleSelected = (event) => {
    if(event.detail.length > 0) {
      this.singleBarTarget.classList.remove('hidden');

      if (this.tableSelectedBrands.size === 1) {
        this.singleBarTarget.classList.remove('hidden');
        this.multiBarTarget.classList.add('hidden');
      } else if (this.tableSelectedBrands.size > 1) {
        this.singleBarTarget.classList.add('hidden');
        this.multiBarTarget.classList.remove('hidden');
      }
    } else {
      this.singleBarTarget.classList.add('hidden');
      this.multiBarTarget.classList.add('hidden');
    }
  }

  submitFromSelection(event) {
    const form = event.target.closest('form');
    this.submitSelection(form, event.params.keepSelections);
    
    event.target.closest("[data-controller=sa--popup]")?.popupController?.close();
  }

  submitSelection = (form, keepSelections) => {
    const formData = new FormData(form);
    this.tableSelectedIds.forEach((id) => {
      formData.append('ids[]', id);
    });

    const url = form.action;
    post(url, {
      body: formData,
      responseKind: 'turbo-stream'
    });

    if (!keepSelections) {
      this.selectableTableOutlets.forEach((outlet) => outlet.selectedValue = []);
    }
  }
}
