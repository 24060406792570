import ComposeController from '../compose_controller';

export default class extends ComposeController {
  static targets = ['progress']
  static values = {
    state: Object,
    converting: Boolean,
    uploading: Boolean,
    failed: Boolean,
    progress: Number
  }

  connect() {
    console.log("media_item_controller connected", this.uploadingValue);
    this.element.assetController = this;
    this.element.mediaItemController = this;
  }

  failedValueChanged() {
    if(this.failedValue) {
      this.element.classList.add('ring-red-500', 'text-red-500');
    } else {
      this.element.classList.remove('ring-red-500', 'text-red-500');
    }
  }

  get failed() {
    return this.failedValue == true;
  }

  disappear() {
    this.element.classList.add('transition-all', 'duration-500', 'opacity-0', 'scale-0');
  }

  autoConvert = (event) => {
    if(event.detail.assetId == this.id) {
      this.convertingValue = true;
    }
  }

  progressValueChanged(value) {
    if(this.hasProgressTarget) {
      this.progressTarget.setAttribute('progress', value);
      if(value == 100) {
        this.progressTarget.classList.add('hidden');
      } 
    }
  }

  convertingValueChanged() {
    if(this.convertingValue) {
      this.element.classList.add('animate-pulse');
    } else {
      this.element.classList.remove('animate-pulse');
    }
  }

  // For asset_alt_controller.js compat
  state() {
    return this.stateValue;
  }

  get id() {
    return this.stateValue.id;
  }
}
