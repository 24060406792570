import SectionController from './section_controller';

export default class extends SectionController {
  static targets = ['fileInput', 'media', 'assetDetail', 'assetDetailWrap', 'multiselect', 'mediascroll', 'assetOption', 'video', 'selectedArea', 'taskMediaContainer', 'checkbox', 'uploadWrap'];
  static values = {
    selectedAssetId: String,
    assetPath: String,
    multiselect: Boolean,
    converting: Boolean
  }

  connect() {
    this.element.galleryController = this;
    this.setMultiselectText();
    this.listenOnManager('asset:updated', this.reloadSelectedAsset);  
    this.listenOnManager('asset:selected', this.updateSelectedArea);
    this.listenOnManager('media:assets_changed', this.updateSelectedArea);
  }

  disconnect() {
    this.listenOffManager('asset:updated', this.reloadSelectedAsset);
    this.listenOffManager('asset:selected', this.updateSelectedArea);
    this.listenOffManager('media:assets_changed', this.updateSelectedArea);
  }

  reloadSelectedAsset = (event) => {
    if(event.detail.assetId == this.selectedAssetIdValue) {
      this.showAssetDetail({ detail: { assetId: this.selectedAssetIdValue } });
    }
  }

  toggleMultiselect() {
    this.multiselectValue = !this.multiselectValue;
    this.setMultiselectText();
  }

  setMultiselectText() {
    this.multiselectTarget.classList.toggle('multiselecting', !this.multiselectValue);
  }

  ensureOpened() {
    this.mediaController.openPicker();
  }

  editImage(assetId, name, url, network) {
    this.composeManager.editImage(assetId, name, url, network).then(asset => {
      this.createAsset(asset);

      if(this.mediaController.assetSelected(assetId)) {
        this.takeAction(this.mediaController.key, 'replace_asset', { asset_id: assetId, new_asset_id: asset.id });
      } else {
        //Should we attach on editing a non-attached asset?
        this.takeAction(this.mediaController.key, 'attach_asset', { asset_id: asset.id });
      }
      this.showAssetDetail({ detail: { assetId: asset.id } });
    });
  }

  fixError = (event) => {
    const assetId = event.params.assetId;
    const constraint = event.params.constraint;
    const dir = event.params.dir;
    const network = event.params.network;
    const name = event.params.name;
    const url = event.params.url;
    const proxyUrl = event.params.proxyUrl;

    if(this.uploading) { 
      alert("Please wait for the image to finish uploading") 
      return
    }

    if(constraint == 'image_size') {
      this.composeManager.autoConvertAsset(assetId);
    } else if(constraint == 'format' || constraint == 'video_format') {
      this.composeManager.autoConvertAsset(assetId)
    } else if(constraint == 'video_length') {
      // TODO
    } else if(constraint == 'video_width') {
      this.composeManager.autoConvertAsset(assetId);
    } else if(constraint == 'image_aspect_ratio') {
      this.editImage(assetId, name, proxyUrl, network);
    }
  }

  showAssetDetail = (event) => {
    //this.uploadWrapTarget.classList.add('right-full');
    this.selectedAssetIdValue = event.detail?.assetId || event.params?.assetId;
    const assetId = event.detail?.assetId || event.params?.assetId;
    this.assetDetailTarget.removeAttribute('complete');
    if(assetId) {
      this.assetDetailTarget.src = "/gallery/asset/" + assetId + "?composable_id=" + this.composeManager.idValue;
      this.assetDetailWrapTarget.classList.add('right-full');
      setTimeout(() => {
        this.assetDetailWrapTarget.classList.remove('right-full');
      }, 100);
    } else {
      this.assetDetailWrapTarget.classList.add('right-full');
      setTimeout(() => {
        this.assetDetailTarget.innerHTML = "";
      }, 100);
    }
  }

  editAsset = (event) => {
    const id = event.params.assetId;
    const name = event.params.name;
    const url = event.params.url;
    this.editImage(id, name, url);
  }

  assetPathValueChanged(value) {
    this.assetDetailTarget.src = value;
  }

  selectFiles = (event) => {
    this.fileInputTarget.click();
  }

  toggleSelectAll = (event) => {
    const assetsChanged = []
    if(this.checkboxTarget.checked === false) {
      this.checkboxTarget.checked = true;
      this.taskMediaContainerTarget.children.forEach((child) => {
        child.assetController.on();
        assetsChanged.push(child.assetController.assetValue.id);
      });
    } else {
      this.checkboxTarget.checked = false;
      this.taskMediaContainerTarget.children.forEach((child) => {
        child.assetController.off();
        assetsChanged.push(child.assetController.assetValue.id);
      });
    }

    this.takeAction(this.mediaController.key, 'assets_changed', { asset_ids: assetsChanged, selected: this.checkboxTarget.checked });
  };

  createAsset(asset) {
    asset.initializing = true;
    this.mediaTarget.insertAdjacentHTML('afterbegin', `
      <sa-asset asset-id="${ asset.id }" 
                class="aspect-square border border-gray-300 rounded-lg"
                title="${ asset.name }"
                src="${ asset.proxy_url }" 
                contenttype="${ asset.content_type }"
                data-action="sa-asset:activate->compose--media#selectAsset sa-asset:select->compose--media#updateMedia"
                data-compose--media-target="assetOption"
                uploading
                selectable
      ></sa-asset>`);
  }


  updateSelectedArea = () => {
    if(this.hasSelectedAreaTarget) {
      this.mediaController.assetTargets.forEach(asset => {
        if(!this.selectedAreaTarget.querySelector(`[asset-id="${asset.assetId}"]`)) {
          this.selectedAreaTarget.insertAdjacentHTML('beforeend', `
            <sa-asset class="asset-item aspect-[4/3] border border-gray-300 rounded-lg"
                      title="${ asset.title }"
                      asset-id="${ asset.assetId }" 
                      src="${ asset.proxyUrl }" 
                      contenttype="${ asset.contentType }"
                      data-action="sa-asset:remove->compose--media#removeMedia:stop"
                      ${ asset.uploading ? 'uploading' : '' }
                      removable
            ></sa-asset>`);
        }
      });
    }
  }

  newFileAdded = (file) => {
    this.composeManager.requestAsset(file, (asset) => {
      this.createAsset(asset);
      this.takeAction(this.mediaController.key, 'attach_asset', { asset_id: asset.id })
    })
  }

  createAssets = (event) => {
    Array.from(this.fileInputTarget.files).forEach(this.newFileAdded);

    // Clear so the same file can be added again
    this.fileInputTarget.value = '';
  }

  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], fileName, {type:mimeType});})
    );
  }

  videoSnapshot() {
    const dataURL = this.assetDetailTarget.querySelector('sa-asset').snapshot();

    this.srcToFile(dataURL, `thumb-${Date.now()}.png`, 'image/png').then((file) => {
      this.composeManager.requestAsset(file, asset => { 
        this.takeAction(
          'media', 
          'really_attach_thumbnail', 
          {asset_id: this.selectedAssetIdValue, thumbnail_asset_id: asset.id}).then(() => {
            setTimeout(() => {
              this.showAssetDetail({ detail: { assetId: this.selectedAssetIdValue } });
            }, 100);
          });
      })
    });
  }

  get mediaController() {
    return this.element.closest('[data-controller=compose--media]').mediaController;
  }
}
