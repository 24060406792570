import SectionController from './section_controller';
import '../../elements/progress_ring';

export default class extends SectionController {
  static targets = ['pickerFile', 'file', 'asset', 'assetArea', 'assetOption', 'gallery'];
  static values = {
    assets: Array
  }
  static classes = ['upload'];

  connect() {
    this.element.mediaController = this;
    super.connect();
  }

  disconnect() {
  }

  assetsValueChanged(newValue, oldValue) {
    //this.dispatchOnManager('media:assets_changed', { assets: newValue });
    this.assetOptionTargets.forEach(option => {
      if(newValue.includes(option.assetId)) {
        option.setAttribute('selected', true);
      } else {
        option.removeAttribute('selected');
      }
    })
  }

  loadUppy = () => {
    window.dispatchEvent(new CustomEvent('loadUppy', { detail: { } }));
  }

  assetSelected = (assetId) => {
    return this.assetsValue.includes(assetId);
  }

  assetTargetConnected = (target) => {
    console.log("Asset target connected", target.assetId);
    this.assetsValue = this.assetsValue.concat([target.assetId]);
    if(this.galleryController) {
      this.galleryController.updateSelectedArea();
    }
    
    // Emit event
    const customEvent = new CustomEvent('assetSelected', { detail: { assetId: target.assetId } });
    this.element.dispatchEvent(customEvent);
  }

  assetTargetDisconnected(target) {
    this.assetsValue = this.assetsValue.filter(assetId => assetId != target.assetId);
    // Emit event
    const customEvent = new CustomEvent('assetDeselected', { detail: { assetId: target.assetId } });
    this.element.dispatchEvent(customEvent);
  }

  hideAssetDetail = (event) => {
    this.galleryController.showAssetDetail({params: {}});
  }

  refreshAsset = (event) => {
    this.takeAction('media', 'refresh_asset', {asset_id: event.target.assetId});
  }

  updateMedia = (event) => {
    const assetId = event.detail.assetId;
    const selected = event.detail.selected;

    console.log("Updating media", assetId, selected);
    if(selected) {
      this.composeManager.addMedia(assetId);
    } else {
      this.composeManager.removeMedia(assetId);
    }

    this.galleryController.showAssetDetail({params: {}});
  }

  assetOptionTargetConnected = (target) => {
    // If target asset id in assetsValue, mark as used
    console.log(this.assetsValue, target.assetId);
    if(this.assetsValue.includes(target.assetId)) {
      //target.uploadAssetController.checkedValue = true;
      target.setAttribute('selected', true);
      this.galleryController.updateSelectedArea();
    }
  }

  assetOptionTargetDisconnected = (target) => {
  }

  assetSelected = (assetId) => {
    return this.assetTargets.find(assetTarget => assetTarget.assetId == assetId)
  }

  toggleMedia = (assetId) => {
    if(this.assetSelected(assetId)) {
      this.composeManager.removeMedia(assetId);
    } else {
      this.composeManager.addMedia(assetId);
    }
  }

  removeMedia = (event) => {
    const assetId = event.params.assetId || event.target.getAttribute('asset-id') || event.currentTarget.getAttribute('asset-id');
    console.log("Removing media", assetId);
    this.composeManager.removeMedia(assetId)
  }

  addMedia = (event) => {
    this.composeManager.addMedia(event.params.assetId);
  }

  disappear = () => {
    this.element.classList.add('transition-all', 'opacity-0', 'scale-0');
  }

  uploadFile = (event) => {
    this.fileTarget.click();
  }

  openPicker = () => {
    //this.element.classList.add('show-media-picker');
    if(!this.element.classList.contains('show-media-picker')) {
      this.picker();
    }
  }


  picker = (event) => {
    // Custom event showPicker
    if(!this.element.classList.contains('show-media-picker')) {
      this.element.dispatchEvent(new CustomEvent('showPicker', { bubbles: true, detail: { } }));
    } else {
      this.element.dispatchEvent(new CustomEvent('hidePicker', { bubbles: true, detail: { } }));
    }
    this.element.classList.toggle('show-media-picker');
  }

  pickerUpload = (event) => {
    this.pickerFileTarget.click();
  }

  selectAsset = (event) => {
    if(this.galleryController.multiselectValue) {
      // Convert event to assetUpdate
      //this.mediaController.updateMedia(event);
      //event.target.uploadAssetController.assetToggle();
      this.toggleMedia(event.params.assetId);
    } else {
      this.openPicker();
      this.galleryController.showAssetDetail(event);
    }
  }


  imageDimensions = (url) => new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = url;
  })

  get state() {
    return {assets: this.assetTargets.map(
      assetTarget => assetTarget.assetController?.state() || assetTarget.state()
    )};
  }

  createPickerAssets = () => {
    Array.from(this.pickerFileTarget.files).forEach(async (file) => {
      await this.composeManager.requestAsset(file, asset => this.takeAction(
        this.key,
        'attach_asset', 
        { asset_id: asset.id },
      ))
    })

    // Clear so the same file can be added again
    this.pickerFileTarget.value = '';
  }

  attachAsset =  async(asset) => {
  }

  createAssets = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      await this.composeManager.requestAsset(file, asset => this.takeAction(
        this.key,
        'attach_asset', 
        { asset_id: asset.id },
      ))
    })

    // Clear so the same file can be added again
    this.fileTarget.value = '';
  }

  get galleryController() {
    if(this.hasGalleryTarget) {
      return this.galleryTarget.galleryController;
    }
  }
}
