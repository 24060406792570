import SaController from './sa_controller';
import { debounce, some } from 'lodash';

export default class extends SaController {
  static targets = ['input', 'overlay', 'checkOnDrop'];

  connect() {
    this.element.addEventListener('dragover', this.dragOver);
    this.element.addEventListener('drop', this.drop);
  }

  
  disconnect() {
    this.element.removeEventListener('dragover', this.dragOver);
    this.element.removeEventListener('drop', this.drop);
  }

  drop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.element.classList.remove('dragging');

    this.inputTarget.files = event.dataTransfer.files;
    this.inputTarget.dispatchEvent(new Event('change'));

    this.checkOnDropTargets.forEach(target => {
      target.checked = true;
    });
  }


  stopDragging = debounce(() => {
    this.element.classList.remove('dragging')
  }, 400);


  dragOver = (event) => {
    if(event.dataTransfer.types[0] != 'Files') return;

    console.log('dragOver');
    event.preventDefault();
    event.stopPropagation();
    this.element.classList.add('dragging');

    this.stopDragging();
  }

  dragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.element.classList.remove('dragging');
  }
}
