import {cable, Turbo} from "@hotwired/turbo-rails"
import '@github/time-elements';
import './elements/networks';
import './elements/sa_asset';

window.cable = cable;
window.Turbo = Turbo;

import './controllers'
import './style/tailwind.css'
import './lib/notification'

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

import { StreamActions } from "@hotwired/turbo"
 
StreamActions.update_stimulus_value = function() {
  const elementId = this.getAttribute("target");
  const key = this.getAttribute("key");
  const value = this.getAttribute("value");

  const elem = document.getElementById(elementId);
  elem.setAttribute(key, value);
}

import TurboSamorph from './lib/samorph'
TurboSamorph.initialize(Turbo.StreamActions)

import MobileHeightCalculator from './lib/mobile_height_calculator'
MobileHeightCalculator.initialize()

setTimeout(() => {
  //Stimulus.debug = true; //window.IS_DEV_ENV;
 
  // Single we have loaded the application js
  window.loaded = true;
  const event = new CustomEvent('application:loaded');
  document.dispatchEvent(event);
});

document.addEventListener("turbo:load", function() {
  document.querySelectorAll('.modal-toggle').forEach((modal) => {
    modal.checked = false;
  })
})

Turbo.StreamActions.animated_remove = async function () {
  this.targetElements.forEach(async (target) => {
    target.animate(
      [
        {
          transform: `scale(1)`,
          transformOrigin: "top",
          height: "auto",
          opacity: 1.0,
        },
        {
          transform: `scale(0.8)`,
          opacity: 0.2,
          height: "80%",
          offset: 0.8,
        },
        {
          transform: `scale(0)`,
          transformOrigin: "top",
          height: 0,
          opacity: 0,
        },
      ],
      {
        duration: 500,
        easing: "ease-out",
      }
    );
    await Promise.all(
      target.getAnimations().map((animation) => animation.finished),
    );
    target.remove();
  });
};
 
Turbo.StreamActions.animated_prepend = async function () {
  this.removeDuplicateTargetChildren();
  this.targetElements.forEach(async (target) => {
    target.prepend(this.templateElement.content);
    target.firstElementChild.animate(
      [
        {
          transform: `scaleY(0.0)`,
          transformOrigin: "top",
          height: "0",
          opacity: 0.0,
        },
        {
          transform: `scale(0.8)`,
          opacity: 0.2,
          height: "80%",
          offset: 0.2,
        },
        {
          transform: `scaleY(1)`,
          transformOrigin: "top",
          height: "auto",
          opacity: 1,
        },
      ],
      {
        duration: 500,
        easing: "ease-out",
      }
    );
  });
};

Turbo.StreamActions.animated_select_table_expand = async function () {
  this.removeDuplicateTargetChildren();
  this.targetElements.forEach(async (target) => {
    const chevron = target.querySelector('a').firstElementChild;
    const tableRef = target.parentNode;
    if (target.classList.contains('expanded')) {
      target.classList.remove('expanded');
      chevron.classList.remove('rotate-90');
      const nextRow = target.nextElementSibling;
      nextRow.animate(
        [
          {
            transform: "scaleY(0)",
            transformOrigin: "top",
            height: "0",
          }
        ],
        {
          duration: 200,
          easing: "ease-out",
        }
      );
      setTimeout(function(){
        nextRow.remove();
      }, 195);
    } else {
      target.classList.add('expanded');
      const targetCellWidths = Array.from(target.cells).map(cell => cell.offsetWidth);
      chevron.classList.add('rotate-90');
      const newRow = tableRef.insertRow(target.rowIndex);
      newRow.id = `subtask-${target.id}`;
      const newCell = newRow.insertCell(0);
      newCell.classList.add('p-0');
      newCell.colSpan = target.cells.length;
      newCell.appendChild(this.templateElement.content);
      const subtaskCells = newCell.querySelectorAll('td');
      targetCellWidths.forEach((width, index) => {
        subtaskCells[index].style.width = `${width}px`;
      });
      newRow.animate(
        [
          {
            transform: "scaleY(0)",
            transformOrigin: "top",
            height: "0"
          },
          {
            transform: "scaleY(1)",
            transformOrigin: "top",
            height: "auto"
          }
        ],
        {
          duration: 200,
          easing: "ease-out",
        }
      );
    }
  })
};
