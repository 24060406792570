import ComposeController from '../compose_controller';
import '../../elements/progress_ring';

export default class extends ComposeController {
  static targets = ['file', 'asset', 'progress', 'image', 'editCheckbox', 'links', 'video'];
  static values = {
    state: Object,
    progress: Number,
    autoConvertable: Boolean,
    converting: Boolean
  }

  connect() {
    this.element.assetController = this;
    this.composeManager.element.addEventListener('upload:progress', this.onProgress);
    this.composeManager.element.addEventListener('upload:success', this.onSuccess);
    this.composeManager.element.addEventListener('upload:fail', this.onFail);

    this.autoConvertableValueChanged();
  }

  disconnect() {
    if(this.composeManager) {
      this.composeManager.element.removeEventListener('upload:progress', this.onProgress);
      this.composeManager.element.removeEventListener('upload:success', this.onSuccess);
      this.composeManager.element.removeEventListener('upload:fail', this.onFail);
    }
    clearInterval(this.refreshInterval)
  }

  autoConvertableValueChanged() {
    if(this.autoConvertableValue) {
      this.composeManager.autoConvertAsset(this.stateValue.id); 
    }
  }

  convertingValueChanged() {
    if(this.convertingValue) {
      // Would be nice for this to just be a broadcast from the server
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(this.requestUpdate, 10000)
      this.startLoading();
    } else {
      clearInterval(this.refreshInterval)
      this.hideLoading();
    }
  }

  modifyAsset() {
    this.composeManager.editImage(this.id, this.name, this.url);
  }

  editAsset() {
    this.editCheckboxTarget.checked = !this.editCheckboxTarget.checked;
    if(!this.editCheckboxTarget.checked) {
      this.requestUpdate();
    }
  }

  state() {
    return this.stateValue;
  }

  requestUpdate = () => {
    if(this.composeManager) {
      this.takeAction('media', 'refresh_asset', {asset_id: this.id});
    } else {
      clearInterval(this.refreshInterval)
    }
  }

  setPreview(file) {
    const url = URL.createObjectURL(file);
    if(this.hasImageTarget) {
      this.imageTarget.src = url;
    }
  }

  onFail = (event) => {
    if(this.id === event.detail.asset.id) {
      this.progressValue = null;
    }
  }

  onSuccess = (event) => {
    if(this.id === event.detail.asset.id) {
      this.progressValue = 100;
      this.takeAction('media', 'upload_complete', {asset_id: this.id})
      this.setPoster();
    }
  }

  setPoster(){
    if(this.hasVideoTarget && !this.element.poster) {
      // Looks up poster for video using Lambda
      this.videoTarget.poster = `/asset/poster_image/${this.id}`;
    }
  }

  get id() {
    return this.stateValue.id;
  }

  get name() {
    return this.stateValue.name;
  }

  get url() {
    return this.stateValue.url;
  }

  onProgress = (event) => {
    if(this.id === event.detail.asset.id) {
      this.progressValue = event.detail.progress*100;
    }
  }

  progressValueChanged(value) {
    if(value > 0) {
      this.linksTarget.classList.remove('group-hover/asset:flex');
      this.progressTarget.classList.remove('hidden');
    }

    this.progressTarget.setAttribute('progress', value);
    if(value == 100) {
      this.linksTarget.classList.add('group-hover/asset:flex');
      this.progressTarget.classList.add('hidden');
    } 
  }

  get uploading() {
    console.log("PROGRESS", this.progressValue);
    return this.progressValue ? this.progressValue != 100 : false;
  }
}

