import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import './tip';
import { capitalize } from 'lodash';

/****************************************
 * Lit version of your networks element
 * to show particular network icons
 * on each asset 
 ****************************************/
class SaNetworks extends LitElement {
  @property({ type: String, reflect: true }) networks = "";

  static styles = css`
    :host {
      display: flex;
      flex-direction: row;
    }

    img {
      border-radius: 50%;
      padding: 0.1rem;
      height: 1.2rem;
      margin-left: 0.2rem;
      background-color: rgba(255, 255, 255, 0.7);
    }

    .error {
      background-color: rgba(255, 0, 0, 0.6);
    }`;
  
  connectedCallback() {
    super.connectedCallback()
  }

  networkBadge(network) {
    if(network == 'twitter') {
      return html`<img src="https://assets.socialassurance.com/logos/logo-x.svg"><sa-tip placement="top">Publishing to X</sa-tip>`;
    } else if(network == 'none') {
      return html`<img class="error" src="https://assets.socialassurance.com/logos/logo-${network}.svg"><sa-tip placement="top">Publishing to no networks</sa-tip>`;
    } else {
      return html`<img src="https://assets.socialassurance.com/logos/logo-${network}.svg"><sa-tip placement="top">Publishing to ${capitalize(network)}</sa-tip>`;
    }
  }

  get networkArray() {
    return this.networks.split(',').filter(network => network.length > 0);
  }

  render() {
    return html`${this.networkArray.map(network => this.networkBadge(network))}`;
  }
}

customElements.define('sa-networks', SaNetworks);
