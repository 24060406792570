import SaController from './sa_controller';
/*
 * Very simple controller for toggling a class
 *
 * This is kinda a dup of some others but I want 
 * it to work a very specific way
 */
export default class extends SaController {
  static targets = ['element', 'focusOn', 'focusOff']
  static values = {
    classes: Array
  }

  off = () => {
    this.elements.forEach((element) => {
      element.classList.remove(this.classesValue);
    });
    this.focusOffTarget.focus();
  }

  on = () => {
    this.elements.forEach((element) => {
      element.classList.add(this.classesValue);
    });
    this.focusOnTarget.focus();
  }

  get elements() {
    if(this.hasElementTarget) {
      return this.elementTargets;
    } else {
      return [this.element];
    }
  }
}
