import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ 'textArea' ]

  connect() {
    this.resize();
  }

  disconnect() {
  }

  resize() {
    this.textAreaTarget.style.height = "";
    this.textAreaTarget.style.height = Math.min(this.textAreaTarget.scrollHeight, 200) + "px";
  }
};
