import MediaInfo from 'mediainfo.js';
import { post } from '@rails/request.js'

class Uploader {
  constructor(eventEmitter, options = {}) {
    this.eventEmitter = eventEmitter;
    this.assets = {};
    this.previews = {};
    this.previewsByUrl = {};
    this.options = options;

    MediaInfo({ format: 'JSON', locateFile: () => 'https://assets.socialassurance.com/mediainfo/MediaInfoModule.wasm' }, (mediainfo) => {
      this.mediainfo = mediainfo;
    });

    // Todo - cleanup on eventEmitter destroy
    this.eventEmitter.addEventListener('sa-asset:find-uploader', this.bindUploader);
  }

  bindUploader = (event) => {
    event.target.uploader = this;
  }

  bindFailed = (assetId) => {
  }

  getAsset(assetId) {
    return this.assets[assetId];
  }

  isFailed(assetId) {
    return this.assets[assetId]?.failed;
  }

  emit(name, detail, target) {
    let event = new CustomEvent(name, {detail})
    if(target) {
      target.dispatchEvent(event);
    } else {
      window.dispatchEvent(event);
    }
  }

  emitProgress(asset, progress) {
    this.assetsUploaded[asset.id].status = "uploading";
    if(this.options.onProgress) {
      this.options.onProgress(asset, progress);
    }

    this.emit('upload:progress', { asset, progress }, this.eventEmitter);
  }

  emitSuccess(asset) {
    this.assetsUploaded[asset.id].status = "success";
    if(this.options.onSuccess) {
      this.options.onSuccess(asset);
    }

    this.emit('upload:success', { asset }, this.eventEmitter);
  }

  emitFailure(asset) {
    console.log("FAILURE", asset);
    this.assetsUploaded[asset.id].status = "failed";

    if(this.options.onFailure) {
      this.options.onFailure(asset);
    }

    this.emit('upload:fail', { asset }, this.eventEmitter);
  }

  doUpload = (options) => {
    const { asset, file, url, fields } = options;
    const formData = new FormData();
    this.assets[asset.id] = asset;
    this.assetsUploaded = this.assetsUploaded || {};
    this.assetsUploaded[asset.id] = asset;

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    // Actual file has to be appended last.
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    //xhr.timeout = 100;
    //xhr.upload.onprogress = (ev) => { this.emitProgress(asset, ev.loaded / ev.total) }

    xhr.upload.addEventListener('progress', (ev) => {
      this.assetsUploaded[asset.id].progress = ev.loaded / ev.total;
      this.emitProgress(asset, ev.loaded / ev.total);
    });

    xhr.upload.addEventListener('loadstart', (ev) => {
      this.assetsUploaded[asset.id].progress = 0;
      this.emitProgress(asset, 0);
    });

    xhr.upload.addEventListener('loadend', (ev) => {
      if (xhr.status != 201 && xhr.status != 0) {
        this.emitFailure(asset)
      }
      //this.emitProgress(asset, 0);
    });

    xhr.upload.addEventListener("error", (ev) => {
      console.log("ERROR", ev);
      this.emitFailure(asset);
    })

    xhr.upload.addEventListener("abort", (ev) => {
      console.log("ABORT", ev);
      this.assetsUploaded[asset.id].failed = true;
      this.emitFailure(asset);
    })

    xhr.upload.addEventListener("timeout", (ev) => {
      console.log("TIMEOUT", ev);
      this.assetsUploaded[asset.id].failed = true;
      this.emitFailure(asset);
    })

    xhr.addEventListener("load", (ev) => {
      console.log("LOAD", ev);
      if (xhr.status === 201) {
        this.emitSuccess(asset);
      } else {
        this.emitFailure(asset)
      }
    });

    xhr.open("POST", url, true);
    xhr.send(formData);
    /*
    xhr.onload = (evt) => {
      if (xhr.status === 201) {
        this.emitSuccess(asset);
      } else {
        this.emitFailure(asset)
      }
    };
    */
  }

  getAssetStatus(assetId) {
    return this.assets[assetId]?.status;
  }

  async requestAsset(file, callback, forceType) {
    const stats = await this.fileStats(file);
    const type = forceType || file.type
    const name = file.name || ('file.' + type.split('/')[1])

    const resp = await post("/actions/refactor_create_presigned_asset", {
      body: {
        content_type: forceType || file.type,
        verify_upload: true,
        size: file.size,
        secured: false,
        name: name.replace("+", ""),
        info: stats
      },
      contentType: "application/json",
      responseKind: "json"
    })
    const json = await resp.json;
    this.doUpload({ asset: json.result.asset, file: file, url: json.result.url, fields: json.result.fields })
    this.previews[json.result.asset.id] = file;
    this.previewsByUrl[json.result.asset.url] = file;
    callback(json.result.asset);
  }

  async fileStats(file) {
    const getSize = () => file.size;
    const readChunk = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
      })

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.mediainfo.analyzeData(getSize, readChunk).then((result) => {
          resolve(result);
        }, 1000);
      });
    });
  }

  getPreview = (assetId) => {
    return this.previews[assetId];
  }

  getPreviewByUrl = (url) => {
    return this.previewsByUrl[url];
  }


}

export default Uploader;
