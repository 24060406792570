// We already have a stimulus version of this but that doesn't work in the shadow dom
// so I borrowed this from the lit.dev tutorial and modified it to work. 
//
// I think it could be useful in general though


/* Taken from https://lit.dev/tutorials/tooltip/#10 */

import {html, css, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {Directive, DirectiveParameters, directive} from 'lit/directive.js';
import {ElementPart, render} from 'lit';

// Positioning library
import {computePosition, autoPlacement, offset, shift} from '@floating-ui/dom';

// Events to turn on/off the tooltip
const enterEvents = ['pointerenter', 'focus'];
const leaveEvents = ['pointerleave', 'blur', 'keydown', 'click'];

@customElement('sa-tip')
export class SimpleTooltip extends LitElement {

  // Lazy creation
  static lazy(target: Element, callback: (target: SimpleTooltip) => void) {
    const createTooltip = () => {
      const tooltip = document.createElement('sa-tip') as SimpleTooltip;
      callback(tooltip);
      target.parentNode!.insertBefore(tooltip, target.nextSibling);
      tooltip.show();
      // We only need to create the tooltip once, so ignore all future events.
      enterEvents.forEach(
        (eventName) => target.removeEventListener(eventName, createTooltip));
    };
    enterEvents.forEach(
      (eventName) => target.addEventListener(eventName, createTooltip));
  }

  static styles = css`
    :host {
      /* Position fixed to help ensure the tooltip is "on top" */
      position: fixed;
      z-index: 1;
      border: 1px solid darkgray;
      background: #ddd;
      padding: 4px;
      border-radius: 4px;
      display: inline-block;
      pointer-events: none;

      /* Animate in */
      opacity: 0;
      transform: scale(0.75);
      transition: opacity, transform;
      transition-duration:  0.33s;
    }

    :host([showing]) {
      opacity: 1;
      transform: scale(1);
    }
  `;

  // Attribute for styling "showing"
  @property({reflect: true, type: Boolean})
  showing = false;

  // Position offset
  @property({type: Number})
  offset = 4;

  @property({reflect: true, type: String})
  placement: 'top'|'bottom' = 'top';

  constructor() {
    super();
    // Finish hiding at end of animation
    this.addEventListener('transitionend', this.finishHide);
  }

  connectedCallback() {
    super.connectedCallback();
    // Setup target if needed
    this.target ??= this.previousElementSibling;
    // Ensure hidden at start
    this.finishHide();
  }

  // Target for which to show tooltip
  _target: Element|null = null;
  get target() {
    return this._target;
  }
  set target(target: Element|null) {
    // Remove events from existing target
    if (this.target) {
      enterEvents.forEach(name => this.target!.removeEventListener(name, this.show));
      leaveEvents.forEach(name => this.target!.removeEventListener(name, this.hide));
    }
    if (target) {
      // Add events to new target
      enterEvents.forEach(name => target!.addEventListener(name, this.show));
      leaveEvents.forEach(name => target!.addEventListener(name, this.hide));
    }
    this._target = target;
  }

  get placements() {
    return this.placement ? [this.placement] : ['top', 'bottom'];
  }

  show = () => {
    this.style.cssText = '';
    computePosition(this.target, this, {
      strategy: 'fixed',
      middleware: [
        offset(this.offset),
        shift(),
        autoPlacement({allowedPlacements: this.placements})
      ],
    }).then(({x, y}: {x: number, y: number}) => {
      this.style.left = `${x}px`;
      this.style.top = `${y}px`;
    });
    this.showing = true;
  };

  hide = () => {
    this.showing = false;
  };

  finishHide = () => {
    if (!this.showing) {
      this.style.display = 'none';
    }
  };

  render() {
    return html`<slot></slot>`;
  }

}

class TooltipDirective extends Directive {
  didSetupLazy = false;
  tooltipContent?: unknown;
  part?: ElementPart;
  tooltip?: SimpleTooltip;
  render(tooltipContent: unknown = '') {}
  update(part: ElementPart, [tooltipContent]: DirectiveParameters<this>) {
    this.tooltipContent = tooltipContent;
    this.part = part;
    if (!this.didSetupLazy) {
      this.setupLazy();
    }
    if (this.tooltip) {
      this.renderTooltipContent();
    }
  }
  setupLazy() {
    this.didSetupLazy = true;
    SimpleTooltip.lazy(this.part!.element, (tooltip: SimpleTooltip) => {
      this.tooltip = tooltip;
      this.renderTooltipContent();
    });
  }
  renderTooltipContent() {
    render(this.tooltipContent, this.tooltip!, this.part!.options);
  }
}

export const tooltip = directive(TooltipDirective);
