import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['pan']
  static values = { startX: Number, startY: Number, direction: String, detach: Boolean }

  connect() {
    this.panTarget.addEventListener('touchmove', this.determinePanDirection.bind(this));
    this.unFreezeAxes();
  }

  disconnect() {
    this.unFreezeAxes();
    this.panTarget.removeEventListener('touchmove', this.determinePanDirection.bind(this));
  }

  directionValueChanged() {
    this.freezeAltAxis(this.directionValue);
  }

  freezeAltAxis(direction) {
    this.detachValue = true;
    if (direction === "horizontal") {
      this.panTarget.style.overflowY = "hidden";
    } else {
      this.panTarget.style.overflowX = "hidden";
    }
  }

  unFreezeAxes() {
    this.panTarget.style.overflowX = "auto";
    this.panTarget.style.overflowY = "auto";
    this.detachValue = false;
  }

  recordStart(event) {
    this.unFreezeAxes();
    this.startXValue = event.touches[0].clientX;
    this.startYValue = event.touches[0].clientY;
  }

  determinePanDirection(event) {
    if (this.detachValue) {
      return;
    } else {
      const deltaX = event.changedTouches[0].clientX - this.startXValue;
      const deltaY = event.changedTouches[0].clientY - this.startYValue;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        this.directionValue = "horizontal";
      } else if (Math.abs(deltaY) > Math.abs(deltaX)) {
        this.directionValue = "vertical";
      } else {
        return;
      }
    }
  }
}
