import morphdom from "morphdom"
import { TurboStreamActions, StreamElement } from "@hotwired/turbo"


function samorph(this: StreamElement) {
  const options = {
    childrenOnly: this.hasAttribute("children-only"),
    skipFromChildren: this.hasAttribute("skip-from-children"),
    onBeforeElUpdated: function(fromEl, toEl) {
      if(fromEl.tagName == 'IMG' && fromEl.dataset.src == toEl.dataset.src) {
          return false
      }
      if(fromEl.dataset.keepContent) {
        return false
      }
      if(fromEl.dataset.alertMorphed) {
        fromEl.dispatchEvent(new CustomEvent('morphed', {}))
      }
      return true
    }
  }

  this.targetElements.forEach(element => {
    morphdom(element, options.childrenOnly ? this.templateContent : this.templateElement.innerHTML, 
             options
            )
  })
}

const initialize = (streamActions: TurboStreamActions) => {
  streamActions.samorph = samorph
}

export default {
  initialize,
  samorph
}

export {
  initialize,
  samorph
}

