import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { close, warning, broke_image, play_arrow, description, link, download } from '../svg';

class SaAsset extends LitElement {
  @property({ type: Boolean, reflect: true }) uploading = false;
  @property({ type: String, reflect: true }) aspect = "";
  @property({ type: Boolean, reflect: true }) converting = false;
  @property({ type: Number, reflect: true }) progress = 0;
  @property({ type: Boolean, reflect: true }) selected = false;
  @property({ type: Boolean, reflect: true }) selectable = false;
  @property({ type: Boolean, reflect: true }) removable = false;
  @property({ type: String, reflect: true }) src = '';
  @property({ type: Boolean, reflect: true }) failed = false;
  @property({ type: Boolean, reflect: true }) warning = false;
  @property({ type: String, reflect: true }) contentType = '';
  @property({ type: String, reflect: true }) poster= '';
  @property({ type: Boolean, reflect: true }) controls = false;
  @property({ type: String, reflect: true }) preview = '';
  @property({ type: Boolean, reflect: true }) lazy = false;
  @property({ type: String, reflect: true }) networks = '';
  @property({ attribute: false }) waiting = false;


  // Leaving a lot of the shell styling up to the using view
  // (border, radius, aspect, etc)
  static styles = css`
    :host {
      background-color: #e8e8e8;
      width: 100%;
      display: flex;
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }

    :host([converting]) {
      animation: pulse 1s infinite ease-in-out alternate;
    }

    .broke svg {
      color: #c20;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .warning svg {
      color: #ca0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .media {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    sa-networks {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
    }

    .media svg {
      color: rgba(255, 255, 255, 0.5);
      position: absolute;
      width: 30%;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    :host([controls]) .media {
      display: none;
    }

    .no-media {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .no-media svg {
      color: rgba(50, 50, 50, 0.7);
      position: absolute;
      width: 40%;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .title {
      height: 2rem;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.7);
    }

    .links {
      height: 4rem;
      with: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
    }

    :host(:hover) .links {
      display: block;
    }

    .open {
      position: absolute;
      background-color: rgb(245, 245, 245);
      width: 3rem;
      height: 2rem;
      border-radius: 0.5rem;
      bottom: 20%;
      left: 5%;
      text-align: center;
    }

    .download {
      position: absolute;
      background-color: rgb(245, 245, 245);
      width: 3rem;
      height: 2rem;
      border-radius: 0.5rem;
      bottom: 20%;
      right: 5%;
      text-align: center;
    }

    .open svg {
      color: rgb(0, 0, 0);
      width: 1.5rem;
      margin-top: 0.2rem;
    }
    
    .download svg {
      color: rgb(0, 0, 0);
      width: 1.5rem;  
      margin-top: 0.2rem;
    }
    
    [type=checkbox]:checked {
      height: 1.5rem;
      width: 1.5rem;
      accent-color: #a00;
    }

    [type=checkbox] {
      margin-right: 0.4rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    .name {
      margin-left: 1rem;
      margin-right: 2rem;
      line-height: 2rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .remover {
      position: absolute;
      top: 0;
      right: 0;
      width: 2rem;
    }

    .title svg {
      cursor: pointer;
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      z-index: 1;
    }

    input[type=checkbox] {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
    progress-ring {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;
  
  connectedCallback() {
    super.connectedCallback()
    this.addEventListener('click', this.activate);

    // Currently uploading, find the source uploading and attach
    if(this.uploading) {
      this.findUploader();
      this.eventSource = this.uploader.eventEmitter;
    }
    if(this.lazy) {
      this.createObserver();
    }
  }

  updated(changedProperties) {
    if(this.converting) {
      this.setConvertingInterval();
    } else if(this.convertingInterval) {
      clearInterval(this.convertingInterval);
    }
  }

  // If converting is set we have a periodic event to see if 
  // in case we want to poll the server for updates
  setConvertingInterval() {
    if(this.convertingInterval) { return; }
    
    this.convertingInterval = setInterval(() => {
      if(!this.converting || (this.closest('html') === null)) { 
        clearInterval(this.convertingInterval);
        this.convertingInterval = null;
      } else {
        this.dispatchEvent(new CustomEvent('sa-asset:converting', { bubbles: true }));
      }
    }, 10000);
  }

  // If we are lazy load
  // create an observer to see if we are in view
  createObserver() {
    this.observer = new IntersectionObserver(
      entries => this.handleIntersect(entries)
    )
    this.observer.observe(this);
  }
  
  // Used with the observer to see if we are in view
  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.observer.unobserve(this);
        this.visible();
      } 
    })
  }

  // We are in view, load the asset
  visible() {
    this.lazy = false;
  }

  // Find any uploader that might be associated with this asset
  // it's assumed to be above the asset in the dom
  // The uploader will set itself below
  findUploader() {
    this.dispatchEvent(new CustomEvent('sa-asset:find-uploader', { bubbles: true }));
  }

  // Getters
  get uploader() {
    return this._uploader;
  }

  get isVideo() {
    return this.contentType.startsWith('video');
  }

  get isImage() {
    return this.contentType.startsWith('image');
  }

  get proxyUrl() {
    return this.getAttribute('proxy-url') || this.getAttribute('src');
  }

  get assetId() {
    return this.getAttribute('asset-id');
  }

  get isMedia() {
    return (this.isImage || this.isVideo) && !this.waiting;
  }

  // Setters
  set uploader(uploader) {
    this._uploader = uploader;
    if(this.uploader.isFailed(this.assetId)) {
      this.failed = true;
    }
  }

  // What to bind to for events related
  // to uploading
  set eventSource(element) {
    if(this._eventSource) {
      this.bindAll(null);
    }

    if(element) {
      this.bindAll(element);
    }
    // Remember the event source to later remove event listeners
    this._eventSource = element;
  }


  // Had an error loading the asset, it's either a fail or currently uploading
  setError = () => {
    this._errorCount = this._errorCount ? this._errorCount + 1 : 1;

    if(this.uploading && this._errorCount < 3) {
      this.setPreview();
    } else if(this.uploading) {
      // Load might just be an issue with uploading
      // wait for the upload to finish
      this.waiting = true;
    } else {
      this.failed = true;
    }
  }

  // On successful load remove failed
  setLoad = () => {
    if(this.previewing) {
      // Preview isn't good enough to remove failed
    } else {
      this.failed = false;
    }
  }

  // Create a preview of the element from a local file currently uploading
  // The assocaited uploader gives us this element
  setPreview = () => {
    const preview = this.uploader?.getPreview(this.assetId);
    if(!preview) { return }
    this.preview = URL.createObjectURL(preview);
  }

  bindAll(element) {
    this.bindSuccess(element);
    this.bindFailed(element);
    this.bindProgress(element);
  }

  bindSuccess(element) {
    // Remove the event listener if it exists from a previous element
    if(this.successSelectedForMe) {
      this._eventSource?.removeEventListener('upload:success', this.successSelectedForMe);
    }

    if(element) {
      // Custom function to select only success for this element
      this.successSelectedForMe = (event) => {
        setTimeout(() => {
          if(event.detail.asset.id === this.assetId) {
            this.uploading = false;
            this.waiting = false;
            this.failed = false;
          }
        }, 100);
      }
      this.successSelectedForMe.saAsset = this;

      element.addEventListener('upload:success', this.successSelectedForMe);
    }
  }

  bindFailed(element) {
    if(this.failedSelectedForMe) {
      this._eventSource?.removeEventListener('upload:fail', this.failedSelectedForMe);
    }

    if(element) {
      // Custom function to select only failed for this element
      this.failedSelectedForMe = (event) => {
        if(event.detail.asset.id === this.assetId) {
          this.uploading = false;
          this.failed = true;
        }
      }
      element.addEventListener('upload:fail', this.failedSelectedForMe);
    }
  }

  bindProgress(element) {
    if(this.progressSelectedForMe) {
      this._eventSource?.removeEventListener('upload:progress', this.progressSelectedForMe);
    }

    if(element) {
      // Custom function to select only progress for this element
      this.progressSelectedForMe = (event) => {
        if(event.detail.asset.id === this.assetId) {
          this.setAttribute('progress', event.detail.progress*100);
        }
      }

      element.addEventListener('upload:progress', this.progressSelectedForMe);
    }
  }

  // Create a snapshot of a video asset
  snapshot() {
    const canvas = document.createElement('canvas');
    const video = this.shadowRoot.querySelector('video');
    if (!video.videoWidth && !video.videoHeight) {
      throw new Error("Error snapshotting video");
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL();
    return dataURL;
  }

  showTitle() {
    return this.getAttribute('title') && this.getAttribute('title').length > 0;
  }

  showLinks() {
   return this.getAttribute('linkurl') && this.getAttribute('linkurl').length > 0;
  }

  activate = () => {
    this.dispatchEvent(new CustomEvent('sa-asset:activate', {
      detail: {
        assetId: this.getAttribute('asset-id')
      }
    }));
  }

  noop(event) {
    event.stopPropagation();
  }

  select(event) {
    event.stopPropagation();
    this.selected = event.target.checked;
    this.dispatchEvent(
      new CustomEvent('sa-asset:select', {
        detail: { selected: this.selected, assetId: this.assetId },
      })
    );
  }

  // State of asset, currently only used in compose for sending to
  // the server
  state() {
    return {
      selected: this.getAttribute('selected') === 'true',
      id: this.getAttribute('asset-id'),
      src: this.getAttribute('src'),
      name: this.getAttribute('title'),
      attachment_id: this.getAttribute('attachment-id')
    }
  }
  
  // Fire off a remove event. Captured by some ancestor
  // to remove the asset from the list
  requestRemoval(event) {
    event.stopPropagation();
    this.dispatchEvent(new CustomEvent('sa-asset:remove'));
  }

  // Some issue with the asset
  renderWarning() {
    return this.warning
      ? html`<span class="warning">${warning}</span>`
      : null;
  }

  // Failed to load the asset
  renderFailed() {
    return this.failed
      ? html`<span class="broke">${broke_image}</span>`
      : null;
  }

  // Visible media
  renderMedia() {
    if(this.lazy) {
      html`<div class="media">${play_arrow}</div>`;
    } else {
      return this.isVideo ?
        html`<video ?controls=${this.controls} src="${this.preview || this.src}" @error=${this.setError} crossorigin="anonymous" poster=${this.poster}></video> <div class="media">${play_arrow}</div>` :
        html`<img src="${this.preview || this.src}" @error=${this.setError} @load=${this.setLoad} />`;
    }
  }

  // Network icons
  renderNetworks() {
    return this.networks 
      ? html`<sa-networks networks=${this.networks}></sa-networks>`
      : null;
  }

  // Just an icon for documents with no visual preview
  renderDocument() {
    return html`<div class="no-media">${description}</div>`;
  }

  renderTitle() {
    return html`<div class="title"><div class="name">${this.getAttribute('title')}</div>
            ${(this.selectable && !this.failed) ? html`<input type="checkbox" .checked=${this.selected} @change=${this.select} @click=${this.noop} />` : ''}
            ${this.removable ? html`<div class="remover" @click=${this.requestRemoval}>${close}</div>` : ''}</div>`;
  }

  renderLinks() {
    return html`<div class="links" onclick="event.stopPropagation()"><a class="open" href="${this.getAttribute('linkurl')}" target="_blank">${link}</a><a class="download" data-turbo="false" href="${this.getAttribute('downloadurl')}">${download}</a></div>`;
  }

  render() {
    return html`
        ${ this.showTitle ? this.renderTitle() : ''} 
        ${this.renderWarning()}
        ${this.renderNetworks()}
        ${this.renderFailed()}
        ${ this.showLinks ? this.renderLinks() : ''}
        ${this.isMedia ? this.renderMedia() : this.renderDocument()}
        ${this.uploading ? html`<progress-ring stroke="4" radius="30" progress=${this.progress}></progress-ring>` : ''}
    `;
  }

}

customElements.define('sa-asset', SaAsset);
