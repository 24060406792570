import SaController from '../sa_controller';
import { debounce } from 'lodash';

export default class extends SaController {
  static targets = ['input', 'display']
  static values = {
    value: String
  }

  connect() {
    this.element.controller = this;
    this.inputTarget.value = this.valueValue
    this.inputTarget.addEventListener('blur', this.close);
    this.valueValueChanged();
  }

  disconnect() {
    this.inputTarget.removeEventListener('blur', this.close);
  }

  open() {
    this.element.classList.add('date-open')
    this.initialValue = this.inputTarget.value;

    this.inputTarget.focus();
    this.inputTarget.showPicker();
  }

  close = debounce(() => {
    this.element.classList.remove('date-open')

    // Emit Close
    this.element.dispatchEvent(new CustomEvent('date:close', { bubbles: true, cancelable: true }));

    if(this.initialValue !== this.valueValue) {
      this.element.dispatchEvent(new CustomEvent('date:change-close', { bubbles: true, cancelable: true }))
      this.initialValue = this.valueValue;
    }
  }, 100)

  clear() {
    this.inputTarget.value = '';
    this.valueValue = '';
    this.displayTarget.textContent = '';
    this.close();
  }

  valueValueChanged() {
    this.displayTarget.textContent = this.inputTarget.value;
    if(this.inputTarget.value) {
      const date = new Date(this.inputTarget.value);
      this.displayTarget.textContent = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      });
    }

    if(this.valueValue) {
      this.element.classList.add('has-value')
    } else {
      this.element.classList.remove('has-value')
    }
  }

  toggle() {
    if(this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  changed() {
    this.close();
    // Set text 
    this.valueValue = this.inputTarget.value;
  }

}
